import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import { z } from "zod";
import { useEffect, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm, useFieldArray } from "react-hook-form";
import { apiSlice } from "../../../store/apiSlice";
import ModalWrapper from "../../../components/global/ModalWrapper";
import { restartAnimation } from "../../../components/global/amp/ToastNotification";
import { useNavigate, useParams } from "react-router-dom";
import { globalActions } from "../../../store/globalSlice";
import { Cropper, CropperRef } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import { useAppDispatch, useAppSelector } from "../../../store";
import { UnsavedModal } from "../../../components/global/amp/UnsavedModal";
import { isSuccessResponse } from "../../../helpers/rtkHelpers";
import { PrimaryButton } from "../../../components/global/PrimaryButton";
import { SingleAttractionTemplate } from "../../../components/global/SingleAttractionTemplate";
import { isTabActive, unlockNewAttractionTab } from "../../../helpers/newAttractionsTabPermission";
import { RevertToSavedModal } from "../../../components/global/RevertToSavedModal";
import { LightningIcon } from "../../../components/amp/LightBulbTooltip";
import { ErrorMessage } from "../../../components/global/ErrorMessage";
import { OutlineButton } from "../../../components/global/OutlineButton";
import { setNewAttractionLeftOverTab } from "../../../helpers/newAttractionsFinishLater";
import {
  getVoScoreTooltip,
  isLightningIconActive,
  useVoScoreNeedImprovementsFields,
} from "../../../hooks/useVoScoreNeedImprovementsFields";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../components/global/Tooltip";

interface IAttractionDetails {
  showPublishingButtons: boolean;
}

export const imageCount: Record<AttractionDetails["listing_package"], number> =
  {
    BASIC: 3,
    ENHANCED: 12,
    PREMIUM: 12,
    STANDARD: 12,
    EVENT: 3,
  };

export const mediaSchema = z
  .object({
    url: z.instanceof(File),
    urlString: z.string(),
    rawFile: z.any(),
    filename: z.string(),
    size: z.number(),
    createdAt: z.date(),
    isPrimary: z.boolean(),
    persisted: z.boolean().or(z.string()),
    image_db_id: z.string().optional(),
  })
  .array()
  .min(3, { message: "At least 3 images are required." });

const AttractionImages: React.FC<IAttractionDetails> = () => {
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [getShowRevertModal, setShowRevertModal] = useState<boolean>(false);
  const [getShowDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [getShowCropModal, setShowCropModal] = useState<boolean>(false);
  const [croppingImage, setCroppingImage] = useState<number>();
  const [selectedImages, setSelectedImages] = useState<number[]>([]);
  const [deletedPersisted, setDeletedPersisted] = useState<string[]>([]);

  const { attractionId } = useParams();
  const {
    data: getAttraction,
    isSuccess: isGetSuccess,
    isFetching,
    refetch,
    error: attractionError,
  } = apiSlice.useGetAttractionQuery(attractionId ?? "");
  const { result } = getAttraction ?? {};

  const [
    updateAttraction,
    {
      isLoading: isLoadingUpdateAttraction,
      isError,
      isSuccess: isUpdateSuccess,
    },
  ] = apiSlice.useUpdateAttractionDraftMutation();

  const [isUploadingImages, setIsUploadingImages] = useState(false);

  const isLoading = isLoadingUpdateAttraction || isUploadingImages;

  const [uploadMedia] = apiSlice.useUploadImageToAttractionMutation();

  const schema = z.object({
    media: mediaSchema,
  });

  type AttractionImagesForm = z.infer<typeof schema>;

  const {
    watch,
    handleSubmit,
    formState: { isValid, isSubmitted, errors, isDirty },
    control,
    setValue,
    getValues,
    reset,
  } = useForm<AttractionImagesForm>({
    resolver: zodResolver(schema),
  });
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "media",
    rules: {
      minLength: 1,
    },
  });

  const [isPublishing, setIsPublishing] = useState(false);

  const getPayload = () => {
    const media = getValues("media");
    const preparedMedia: (
      | {
          key: string | true;
          data: string;
          isPrimary?: boolean;
        }
      | {
          key: string | true;
          id?: string;
        }
      | {
          key: string | true;
          id?: string;
          isPrimary: boolean;
        }
    )[] = media.map((m, index) => {
      const key = `media_${index + 1}`;
      const isFirstPrimary = index === 0 && !media.find((m) => m.isPrimary);

      if (m.persisted) {
        return {
          key,
          //...((deletedPersisted.length > 0 || deletedPersisted.findIndex(d => d === key) !== -1) ? { data: m.url }: {}), - 23.07.25 WJC: Removed this line as not sure what it's doing, it just adds the base64 of each media json item if at least 1 item has been removed due to an incorrect OR condition (i'm guessing), but not sure why adding the base64 is required anyway
          isPrimary: isFirstPrimary || !!m.isPrimary,
          id: result?.media_json?.find((r) => r.key === m.persisted)?.id,
        };
      }

      return {
        key,
        data: m.url,
        isPrimary: isFirstPrimary || !!m.isPrimary,
      };
    });

    new Set(deletedPersisted).forEach((m) => {
      const fullMedia = result?.media_json?.find((r) => r.id === m);
      if (fullMedia) {
        preparedMedia.unshift({
          ...fullMedia,
          // @ts-ignore
          toRemove: true,
        });
      }
    });
    return {
      media_json: preparedMedia,
    };
  };

  const isNewMedia = (
    obj:
      | {
          key: string | true;
          data: string;
          isPrimary?: boolean | undefined;
          toRemove?: boolean,
        }
      | {
          key: string | true;
          toRemove?: boolean,
        }
      | {
          key: string | true;
          isPrimary: boolean;
          toRemove?: boolean,
        }
  ): obj is {
    key: string | true;
    data: string;
    isPrimary?: boolean | undefined;
    toRemove?: boolean,
  } => {
    return (obj as any).data !== undefined;
  };
  function later() {
    setNewAttractionLeftOverTab(attractionId ?? "", "ATTRACTION_IMAGES");
    dispatch(globalActions.setUnsavedModalTempDisabled(true));
    setTimeout(() => {
      navigate("/dashboard");
      dispatch(globalActions.setUnsavedModalTempDisabled(false));
    }, 100);
  }
  const isClaim =
  result?.ownership_claim?.created_by_user?.email !==
  result?.creation_claim?.created_by_user?.email;
  function afterSubmit() {
    if (!isStepperActive) return;
    unlockNewAttractionTab(
      attractionId ?? "",
      hasPaid || isClaim ? "ATTRACTION_CHANGE_LOG" : "ATTRACTION_PAY_PUBLISH"
    );

    dispatch(globalActions.setUnsavedModalTempDisabled(true));

    setTimeout(() => {
      navigate(
        `/attractions/${attractionId}/${
          hasPaid || isClaim ? "change-log" : "pay-publish"
        }`,
        {
          state: {
            onboardingComplete: hasPaid || isClaim ? true : false,
          },
        }
      );
      dispatch(globalActions.setUnsavedModalTempDisabled(false));
    }, 100);
  }
  const [uploadCompleteModal, setUploadCompleteModal] = useState(false);
  const onSubmit = (
    publish: boolean = false,
    isSilent: boolean = false,
    finishLater = false
  ) => {
    const fn: SubmitHandler<AttractionImagesForm> = async (e) => {
      setIsPublishing(publish);

      setIsUploadingImages(true);
      const payload = getPayload();
      for (let i = 0; i < payload.media_json.length; i++) {
        const media = payload.media_json[i];
        if (isNewMedia(media)) {
          if (!attractionId) {
            console.log("ERROR Uploading Media, no attraction id!");
            return;
          }

          const formData = new FormData();
          formData.append("file", media.data);
          const uploadedMedia = await uploadMedia({
            file: formData,
            venueId: attractionId,
          });
          if (isSuccessResponse(uploadedMedia)) {
            payload.media_json[i] = {
              key: media.key,
              isPrimary: media.isPrimary ?? false,
              id: uploadedMedia.data.id,
            };
          } else {
            console.log("ERROR Uploading Media", uploadedMedia.error);
          }
        }
      }

      await updateAttraction({
        id: attractionId,
        publish,
        payload: payload,
      });

      setIsUploadingImages(false);

      if (!isSilent) {
        restartAnimation();
      }

      if (!finishLater && isStepperActive) {
        setUploadCompleteModal(true);
      }
    };
    return fn;
  };

  const { user } = useAppSelector((state) => state.global);
  const bypassQueue =
    user?.role === "ADMIN" ||
    ["ENHANCED", "PREMIUM"].includes(result?.listing_package ?? "");
  useEffect(() => {
    if (isUpdateSuccess) {
      reset({}, { keepValues: true });
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: isPublishing
            ? bypassQueue
              ? "Published"
              : "Submitted for approval"
            : "Your changes have been saved",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: isPublishing
            ? "Cannot publish attraction"
            : "Cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  useEffect(() => {
    setDeletedPersisted([]);
  }, [isUpdateSuccess]);

  const createMedia = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files;
    let length = fields.length;

    if (files && files[0] && length < 12) {
      const correctType = ["image/jpeg", "image/png"].includes(files[0].type);
      const correctSize = files[0].size <= 5 * 1024 * 1024;
      if (correctType && correctSize) {
        append({
          url: files[0],
          urlString: await fileToDataString(files[0]),
          rawFile: files[0],
          filename: files[0].name,
          size: files[0].size,
          isPrimary: false,
          createdAt: new Date(),
          persisted: false,
        });

        setCroppingImage(length);
        setShowCropModal(true);
      } else {
        dispatch(
          globalActions.setToastNotifcation({
            ...toastNotification,
            type: "ERROR",
            message: correctType
              ? "Unsupported file type"
              : correctSize
              ? "File is too large"
              : "Invalid file",
            attractionApprovalStatus: "",
            attractionImage: "",
            attractionName: "",
            attractionAddress: "",
          })
        );
        restartAnimation();
      }
    }

    event.target.value = "";
  };

  const togglePrimary = (index: number) => {
    if (fields[index].isPrimary) {
      return;
    }

    fields.forEach((field, i) => {
      if (field.isPrimary) {
        update(i, {
          ...field,
          isPrimary: false,
        });
      }
    });

    update(index, {
      ...fields[index],
      isPrimary: !fields[index].isPrimary,
    });
  };

  const toggleSelect = (index: number) => {
    if (selectedImages.includes(index)) {
      setSelectedImages(selectedImages.filter((i) => i !== index));
    } else {
      setSelectedImages([...selectedImages, index]);
    }
  };

  const removeSelected = () => {
    selectedImages.forEach((index) => {
      const field = fields[index];

      if (field.persisted) {
        setDeletedPersisted((current) => [
          ...current,
          field.image_db_id as string,
        ]);
      }
    });
    remove(selectedImages);
    setSelectedImages([]);
    setShowDeleteModal(false);
  };

  const removeSingle = (index: number) => {
    const field = fields[index];

    if (field.persisted) {
      setDeletedPersisted([...deletedPersisted, field.image_db_id as string]);
    }

    remove(index);
  };

  const cropImage = async (value: File) => {
    const index = croppingImage ?? 0;
    update(index, {
      ...getValues(`media.${index}`),
      url: value,
    });
    setShowCropModal(false);
  };

  const cancelCrop = () => {
    const index = croppingImage ?? 0;
    remove(index);
    setShowCropModal(false);
  };

  const [loadingImages, setLoadingImages] = useState(false);

  const isStepperActive =
    user?.role !== "ADMIN" && !result?.finished_onboarding;
  const hasPaid = result?.published_version?.has_paid_to_publish || result?.published_version?.skip_payment;
  useEffect(() => {
    if (result && isGetSuccess && !isFetching) {
      (async () => {
        setLoadingImages(true);
        const allMedia = await Promise.all(
          result?.media_json?.map(async (media) => {
            const data = await fetchFileData(media.cloudinary_url);
            return {
              url: data.data,
              urlString: await fileToDataString(data.data),
              filename: media.public_id,
              size: data.size,
              createdAt: new Date(media.created_at),
              isPrimary: media.isPrimary ?? false,
              persisted: media.key,
              image_db_id: media.id,
            };
          }) ?? []
        );

        setValue("media", allMedia);
        setShowRevertModal(false);
        setLoadingImages(false);

        const isAlreadyUploadCompleted = (!hasPaid && !isClaim) ? isTabActive(attractionId || "", 'ATTRACTION_PAY_PUBLISH') === 'UNLOCKED' : false
        if (allMedia.length >= 3 && isStepperActive && !isAlreadyUploadCompleted) {
          setUploadCompleteModal(true);
        } 
      })();
    }
  }, [result, isFetching, isStepperActive, setValue,isGetSuccess ]);

  useEffect(() => {
    document.title = "Attraction Images | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 403
    ) {
      navigate("/forbidden", { replace: true });
    }
  }, [attractionError]);

 
  useEffect(() => {
    dispatch(
      globalActions.setSaveCurrentTabStep({
        attractionId: attractionId ?? "",
        key: "ATTRACTION_IMAGES",
      })
    );
  }, [attractionId, dispatch]);

  const { data: getMetrics } = apiSlice.useGetVenueOptScoreMetricsQuery();
  const voScoreImprovements = useVoScoreNeedImprovementsFields({
    allMetrics: getMetrics?.result || [],
    completedMetrics:
      getAttraction?.result?.published_version?.optimisation_scores || [],
      results: result
  })["ATTRACTION_IMAGES"];

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <ModalWrapper
        className="w-[95%] md:w-[768px]"
        open={uploadCompleteModal}
        setOpen={setUploadCompleteModal}
        closeOnOverlay={false}
        hideCloseButton={true}
        customContainerClassName={`relative overflow-y-hidden flex flex-col w-full pl-[43px] pr-[20px] bg-white rounded-lg overflow-y-auto overflow-x-hidden pt-[38px] pb-[25px]`}
      >
        <div>
          <img
            src={"/assets/amp/doodle.png"}
            className={`xl:block not-sr-only absolute select-none pointer-events-none`}
            alt=""
          />
          <h1 className="font-fredoka text-4xl text-[#2D1838] leading-[43px]">
            Attraction upload complete!
          </h1>
          <p className="mt-5 text-[#212121] text-sm leading-[21px] font-normal">
            Next, submit your content for approval. Please allow up to 5 working
            days for our team to review your content before it goes live
          </p>

          <div className="flex flex-row mt-10">
            <PrimaryButton
              onClick={() => {
                setUploadCompleteModal(false);
                afterSubmit();
              }}
              scale="sm"
            >
              Continue
            </PrimaryButton>
          </div>
        </div>
      </ModalWrapper>
      <Header
        title="Edit Attraction"
        mobilePublishButtons={true}
        showPublishingButtons
        triggerValidation={handleSubmit}
        saveFn={onSubmit}
        getPayload={getPayload}
        isDirty={isDirty}
      />

      <SingleAttractionTemplate
        result={result}
        attractionId={attractionId}
        isAttraction={true}
        doneWalkthrough={result ? result?.done_walkthrough ?? false : undefined}
        forceShowTitle={true}
        name={""}
      >
        <form onSubmit={handleSubmit(onSubmit(false))}>
          <fieldset className="relative flex flex-col flex-wrap w-full mb-4">
            {isLoading && (
              <div className="absolute bg-white bg-opacity-70 top-0 right-0 w-full h-full z-[5] flex flex-col justify-center items-center text-cs-gray">
                <p className="font-bold">Uploading the images</p>
                <p className="font-bold">this might take a few minutes...</p>
              </div>
            )}
            <legend className="mb-3 text-[22px] font-extrabold text-black">
              📸 Image Upload{" "}
              <span className="text-base font-normal">
                (Upload up to {imageCount[result?.listing_package ?? "BASIC"]}{" "}
                images){" "}
                {!isStepperActive && isLightningIconActive(voScoreImprovements.images) && (
                  <LightningIcon
                    tooltip={getVoScoreTooltip(voScoreImprovements.images)}
                  />
                )}
                {result?.listing_package !== 'PREMIUM' && <Tooltip placement="bottom-start">
                  <TooltipTrigger>
                    <svg
                      className="inline-block ml-2"
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.95834 3.87504H6.04168V2.79171H4.95834M5.50001 9.83337C3.11126 9.83337 1.16668 7.88879 1.16668 5.50004C1.16668 3.11129 3.11126 1.16671 5.50001 1.16671C7.88876 1.16671 9.83334 3.11129 9.83334 5.50004C9.83334 7.88879 7.88876 9.83337 5.50001 9.83337ZM5.50001 0.083374C4.78868 0.083374 4.08432 0.22348 3.42714 0.495693C2.76996 0.767906 2.17283 1.16689 1.66985 1.66988C0.654026 2.6857 0.0833435 4.06345 0.0833435 5.50004C0.0833435 6.93663 0.654026 8.31438 1.66985 9.3302C2.17283 9.83319 2.76996 10.2322 3.42714 10.5044C4.08432 10.7766 4.78868 10.9167 5.50001 10.9167C6.9366 10.9167 8.31435 10.346 9.33017 9.3302C10.346 8.31438 10.9167 6.93663 10.9167 5.50004C10.9167 4.78871 10.7766 4.08435 10.5044 3.42717C10.2321 2.76999 9.83316 2.17286 9.33017 1.66988C8.82719 1.16689 8.23006 0.767906 7.57288 0.495693C6.9157 0.22348 6.21134 0.083374 5.50001 0.083374ZM4.95834 8.20837H6.04168V4.95837H4.95834V8.20837Z"
                        fill="#616C7A"
                      />
                    </svg>
                  </TooltipTrigger>
                  <TooltipContent className="Tooltip">
                    <div className="w-[276px]">
                      <div className="bg-[#EDE8F8] py-2.5 px-5 rounded-lg text-sm font-normal font-fredoka leading-[21px] text-[#6836D1]">
                        You can add more photos with an upgraded listing plan
                      </div>
                    </div>
                  </TooltipContent>
                </Tooltip>}
              </span>
            </legend>

            <p className="mt-2 text-sm font-normal text-black">
              The image you select as 'Primary Image' will be the one used as
              the main image for your attraction and will be shown most often
              across our website. Make sure this image showcases the best of
              your attraction.
            </p>
            <div className="text-sm font-normal text-black bg-[#F1F1F1] w-full md:w-1/2 mt-5 p-2.5 rounded-lg max-w-[466px]">
              Image Requirements:
              <ul className="pl-5 list-disc">
                <li className="my-1 text-sm font-normal text-black">
                  JPEG or PNG only
                </li>
                <li className="my-1 text-sm font-normal text-black">
                  Max 5mb image size
                </li>
                <li className="my-1 text-sm font-normal text-black">
                  No text, watermarks or graphics allowed
                </li>
                <li className="my-1 text-sm font-normal text-black">
                  Ensure you only upload images you have the permission to use
                </li>
              </ul>
            </div>

            <div
              className={`flex gap-6 text-cs-gray transition-all duration-150 ${
                selectedImages.length === 0
                  ? "opacity-0 pointer-events-none max-h-0"
                  : "max-h-[54px] h-[54px] items-end"
              }`}
            >
              <button type="button" onClick={() => setSelectedImages([])}>
                Unselect all
              </button>

              {selectedImages.length === 1 && (
                <button
                  type="button"
                  onClick={() => togglePrimary(selectedImages[0])}
                >
                  Set as primary
                </button>
              )}

              <button
                className="flex items-center"
                type="button"
                onClick={() => setShowDeleteModal(true)}
              >
                Deleted selected images
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                  />
                </svg>
              </button>
            </div>

            <div className="flex flex-wrap max-w-[1750px] gap-x-6 gap-y-4 mt-6">
              {fields.map((field, index) => (
                <Media
                  className="w-full sm:w-[calc(50%-1.5rem)] md:w-[calc(25%-1.5rem)] [@media(min-width:2400px)]:w-[calc(16.666%-1.5rem)]"
                  key={field.id}
                  index={index}
                  size={field.size}
                  createdAt={field.createdAt}
                  url={field.url}
                  isPrimary={
                    (index === 0 &&
                      !watch("media")?.find((m) => m.isPrimary)) ||
                    field.isPrimary
                  }
                  filename={field.filename}
                  checked={selectedImages.includes(index)}
                  togglePrimary={togglePrimary}
                  remove={removeSingle}
                  toggleSelect={toggleSelect}
                />
              ))}

              {Array.apply(
                null,
                Array(12 - fields.length < 0 ? 0 : 12 - fields.length)
              ).map((x, i) => (
                <div
                  key={i}
                  className="flex flex-col w-full sm:w-[calc(50%-1.5rem)] md:w-[calc(25%-1.5rem)] [@media(min-width:2400px)]:w-[calc(16.666%-1.5rem)]"
                >
                  <div className="relative flex mt-2">
                    <div
                      className={`flex justify-center items-center text-center px-10 w-full aspect-[16/9] border-2 border-dotted border-[#CFDBD5] cursor-pointer relative ${
                        i !== 0 ? "opacity-50 pointer-events-none" : ""
                      }`}
                    >
                      {i === 0 ? (
                        <span className="text-cs-gray text-xs leading-[18px]">
                          <p>Drop image here</p>
                          <p>Upload image</p>
                        </span>
                      ) : (
                        <span>
                          <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="camera-01">
                              <g id="Icon">
                                <path
                                  d="M3.19141 11.963C3.19141 11.4864 3.19141 11.2482 3.21129 11.0475C3.40307 9.11187 4.93431 7.58063 6.86993 7.38885C7.07062 7.36897 7.32175 7.36897 7.824 7.36897C8.01753 7.36897 8.1143 7.36897 8.19645 7.36399C9.24555 7.30046 10.1642 6.63832 10.5562 5.66316C10.5869 5.58679 10.6156 5.5007 10.673 5.32853C10.7304 5.15635 10.7591 5.07026 10.7898 4.9939C11.1818 4.01873 12.1005 3.3566 13.1496 3.29306C13.2318 3.28809 13.3225 3.28809 13.504 3.28809H20.0847C20.2662 3.28809 20.3569 3.28809 20.4391 3.29306C21.4882 3.3566 22.4069 4.01873 22.7989 4.9939C22.8296 5.07026 22.8583 5.15635 22.9157 5.32853C22.9731 5.5007 23.0018 5.58679 23.0325 5.66316C23.4245 6.63832 24.3431 7.30046 25.3922 7.36399C25.4744 7.36897 25.5712 7.36897 25.7647 7.36897C26.2669 7.36897 26.5181 7.36897 26.7188 7.38885C28.6544 7.58063 30.1856 9.11187 30.3774 11.0475C30.3973 11.2482 30.3973 11.4864 30.3973 11.963V22.6043C30.3973 24.8898 30.3973 26.0325 29.9525 26.9055C29.5613 27.6733 28.937 28.2976 28.1691 28.6889C27.2961 29.1337 26.1534 29.1337 23.8679 29.1337H9.72082C7.43531 29.1337 6.29256 29.1337 5.41961 28.6889C4.65174 28.2976 4.02744 27.6733 3.6362 26.9055C3.19141 26.0325 3.19141 24.8898 3.19141 22.6043V11.963Z"
                                  stroke="#CFDBD5"
                                  strokeWidth="2.59653"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.7943 23.0123C19.7994 23.0123 22.2355 20.5763 22.2355 17.5712C22.2355 14.5661 19.7994 12.13 16.7943 12.13C13.7893 12.13 11.3532 14.5661 11.3532 17.5712C11.3532 20.5763 13.7893 23.0123 16.7943 23.0123Z"
                                  stroke="#CFDBD5"
                                  strokeWidth="2.59653"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                      )}

                      <input
                        className="absolute inset-0 w-full h-full opacity-0 appearance-none cursor-pointer"
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={createMedia}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="flex justify-center mt-2 text-cs-gray">
                      {i === 0 && fields.length === 0 ? (
                        <span className="text-black text-sm font-bold">
                          Primary Image
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </fieldset>

          {isStepperActive ? (
            <div>
              <div className="h-[1px] bg-[#D9D9D9] w-full my-5"></div>
              <div className="flex items-center gap-2.5 justify-end w-full">
                <OutlineButton
                  className="h-9 text-sm px-2.5 leading-[16px]"
                  scale="none"
                  type="button"
                  disabled={isLoading || isFetching || loadingImages}
                  onClick={async () => {
                    // @ts-ignore
                    await onSubmit(false, false, true)();
                    later();
                  }}
                >
                  <span className="flex justify-center items-center">
                    {(isLoading || isFetching || loadingImages) && (
                      <LoadingSpinner />
                    )}
                    Save & Finish Later
                  </span>
                </OutlineButton>
                <PrimaryButton
                  scale="sm"
                  type="submit"
                  className="w-[145px] text-center px-2"
                  loading={isLoading || isFetching || loadingImages}
                >
                  {isLoading ? (
                    <span className="flex justify-center items-center">
                      <LoadingSpinner />
                      Uploading
                    </span>
                  ) : (
                    "Next"
                  )}
                </PrimaryButton>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-5 max-w-[145px] mt-5">
              <PrimaryButton
                scale="sm"
                type="submit"
                loading={isLoading || isFetching || loadingImages}
                className="px-[25px]"
              >
                {isLoading ? (
                  <span className="flex justify-center">
                    <LoadingSpinner />
                    Uploading
                  </span>
                ) : (
                  "Save changes"
                )}
              </PrimaryButton>
            </div>
          )}

          {isSubmitted && !isValid && Object.keys(errors).length !== 0 && (
            <ErrorMessage className="mt-2.5">
              {errors?.media?.message
                ? errors?.media?.message
                : "Please fix the errors to continue"}
            </ErrorMessage>
          )}
        </form>

        {getShowCropModal && (
          <ModalWrapper
            closeOnOverlay={false}
            className="w-[616px] min-h-cs-394 px-0 sm:px-10 xl:px-0"
            open={getShowCropModal}
            setOpen={setShowCropModal}
            onClose={cancelCrop}
          >
            <h3 className="mb-3 text-2xl font-bold text-cs-gray">Crop Image</h3>

            <ImageCropper
              file={fields[croppingImage ?? 0]?.rawFile ?? ""}
              stencilAspectRatio={16 / 9}
              onAccept={cropImage}
              onCancel={cancelCrop}
            />
          </ModalWrapper>
        )}

        <ModalWrapper
          className="w-[500px] px-10 xl:px-0"
          open={getShowDeleteModal}
          setOpen={setShowDeleteModal}
        >
          <h3 className="text-2xl font-bold text-cs-gray">Delete Images?</h3>
          <p className="text-base font-normal my-7 text-cs-gray">
            You are about to delete {selectedImages.length} image(s) from your
            gallery.
          </p>
          <h4 className="text-lg font-bold text-cs-gray">Are you sure?</h4>

          <div className="flex flex-row mt-10">
            <button
              className="px-6 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
              onClick={removeSelected}
            >
              Yes, Delete them
            </button>

            <button
              className="ml-20 text-sm text-cs-gray hover:text-cs-pink hover:underline"
              onClick={() => {
                setShowDeleteModal(false);
              }}
            >
              No, Don't delete
            </button>
          </div>
        </ModalWrapper>

        <RevertToSavedModal
          getShowRevertModal={getShowRevertModal}
          setShowRevertModal={setShowRevertModal}
          refetch={refetch}
        />

        <UnsavedModal isDirty={isDirty} />
      </SingleAttractionTemplate>
      <Footer />
    </div>
  );
};

const Media: React.FC<{
  className?: string;
  index: number;
  url: File;
  filename: string;
  size: number;
  createdAt: Date;
  isPrimary: boolean;
  checked: boolean;
  togglePrimary: (index: number) => void;
  remove: (index: number) => void;
  toggleSelect: (index: number) => void;
}> = ({
  className,
  index,
  url,
  filename,
  size,
  createdAt,
  isPrimary,
  checked,
  togglePrimary,
  remove,
  toggleSelect,
}) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const [urlString, setUrlString] = useState("");

  useEffect(() => {
    (async () => {
      setUrlString(await fileToDataString(url));
    })();
  }, [url]);
  return (
    <>
      <div className={`flex flex-col ${className ?? ""}`}>
        <div className="relative flex mt-2">
          <div className="absolute flex justify-between w-full p-4">
            <input
              type="checkbox"
              checked={checked}
              onChange={() => toggleSelect(index)}
            />

            <span className="text-sm">
              <button
                className="text-red-700"
                type="button"
                onClick={() => setDeleteModal(true)}
              >
                <svg
                  width="20"
                  height="23"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.90625 4.20508V2.14258H13.0938V4.20508H6.90625ZM4.84375 4.20508V1.45508C4.84375 1.09041 4.98862 0.740669 5.24648 0.482806C5.50434 0.224944 5.85408 0.0800781 6.21875 0.0800781L13.7812 0.0800781C14.1459 0.0800781 14.4957 0.224944 14.7535 0.482806C15.0114 0.740669 15.1562 1.09041 15.1562 1.45508V4.20508H18.5938C18.8673 4.20508 19.1296 4.31373 19.323 4.50712C19.5163 4.70052 19.625 4.96282 19.625 5.23633C19.625 5.50983 19.5163 5.77214 19.323 5.96553C19.1296 6.15893 18.8673 6.26758 18.5938 6.26758H18.0919L17.0702 19.5405C17.0172 20.2313 16.7054 20.8767 16.1971 21.3476C15.6887 21.8184 15.0214 22.08 14.3285 22.0801H5.6715C4.97861 22.08 4.31127 21.8184 3.80294 21.3476C3.29461 20.8767 2.98276 20.2313 2.92975 19.5405L1.90813 6.26758H1.40625C1.13275 6.26758 0.870443 6.15893 0.677046 5.96553C0.483649 5.77214 0.375 5.50983 0.375 5.23633C0.375 4.96282 0.483649 4.70052 0.677046 4.50712C0.870443 4.31373 1.13275 4.20508 1.40625 4.20508H4.84375ZM3.9775 6.26758H16.0225L15.0133 19.3823C15.0001 19.555 14.9223 19.7163 14.7953 19.8341C14.6684 19.9519 14.5017 20.0174 14.3285 20.0176H5.6715C5.49834 20.0174 5.33163 19.9519 5.20468 19.8341C5.07774 19.7163 4.99991 19.555 4.98675 19.3823L3.9775 6.26758Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </span>
          </div>

          <img
            className="w-full aspect-[16/9] object-cover rounded-sm"
            src={urlString}
            alt={filename}
          />
        </div>

        <div>
          <div className="flex justify-center mt-2 text-cs-gray">
            {isPrimary ? (
              <span className="text-black text-sm font-bold">
                Primary Image
              </span>
            ) : null}
          </div>
        </div>
      </div>

      <ModalWrapper
        className="w-[500px] px-10 xl:px-0"
        open={deleteModal}
        setOpen={setDeleteModal}
      >
        <h3 className="text-2xl font-bold text-cs-gray">Delete Image?</h3>
        <p className="text-base font-normal my-7 text-cs-gray">
          You are about to delete a image from your gallery.
        </p>
        <h4 className="text-lg font-bold text-cs-gray">Are you sure?</h4>

        <div className="flex flex-row mt-10">
          <button
            className="px-6 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
            onClick={() => remove(index)}
          >
            Yes, Delete it
          </button>

          <button
            className="ml-20 text-sm text-cs-gray hover:text-cs-pink hover:underline"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            No, Don't delete it
          </button>
        </div>
      </ModalWrapper>
    </>
  );
};

const ImageCropper: React.FC<{
  file: File;
  stencilAspectRatio?: number;
  onAccept: (value: File) => void;
  onCancel: () => void;
}> = ({ file, stencilAspectRatio = 1 / 1, onAccept, onCancel }) => {
  const cropperRef = useRef<CropperRef>(null);
  const [cropperImage, setCropperImage] = useState<string>("");

  useEffect(() => {
    const objectUrl = URL.createObjectURL(file);
    setCropperImage(objectUrl);

    return () => URL.revokeObjectURL(objectUrl); // Clean up the object URL
  }, [file]);

  const accepted = async () => {
    if (cropperRef.current) {
      const canvas = cropperRef.current.getCanvas();
      if (canvas) {
        canvas.toBlob((blob) => {
          if (blob) {
            const newFile = new File([blob], file.name, {
              type: blob.type,
              lastModified: Date.now()
            });
            onAccept(newFile);
          }
        }, file.type);
      }
    }
  };

  return (
    <>
      <div className="w-full sm:w-[80%] max-w-full aspect-square overflow-none">
        {cropperImage && (
        <Cropper
          className="w-full max-w-full aspect-square"
          ref={cropperRef}
          src={cropperImage}
          resize-image={{
            adjustStencil: true,
          }}
          stencilProps={{
            aspectRatio: stencilAspectRatio,
            movable: true,
            resizable: true,
            handlers: true,
          }}
        />
        )}
      </div>

      <div className="flex flex-row mt-10">
        <button
          className="px-6 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
          onClick={accepted}
        >
          Save
        </button>

        <button
          className="ml-20 text-sm text-cs-gray hover:text-cs-pink hover:underline"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

function fileToDataString(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      resolve(event.target?.result as string);
    };
    reader.onerror = reject;
  });
}

async function fetchFileData(
  url: string
): Promise<{ size: number; data: File }> {
  const response = await fetch(url);
  const contentLength = response.headers.get("Content-Length");
  const fileSize = contentLength
    ? Number.parseInt(contentLength, 10)
    : (await response.body
        ?.getReader()
        ?.read()
        .then((result) =>
          result.done ? result.value?.length || 0 : Infinity
        )) || 0;

  if (!response.ok) {
    throw new Error("Failed to fetch the file.");
  }

  const blob = await response.blob();
  const file = new File([blob], `file-${generateUUIDv4()}`, {
    type: blob.type,
    lastModified: Date.now(),
  });

  return { size: fileSize, data: file };
}
function generateUUIDv4(): string {
  return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(
    /[018]/g,
    (c: number) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
  );
}
// function base64ToFile(base64: string): Promise<File> {
//   return new Promise((resolve, reject) => {
//     // Extract the MIME type and Base64 data
//     const match = base64.match(/^data:(.*?);base64,(.*)$/);
//     if (!match) {
//       return reject(new Error("Invalid Base64 string"));
//     }

//     const mimeType = match[1];
//     const base64Data = match[2];

//     // Decode Base64 data to binary string
//     const binaryString = atob(base64Data);
//     const len = binaryString.length;
//     const bytes = new Uint8Array(len);

//     for (let i = 0; i < len; i++) {
//       bytes[i] = binaryString.charCodeAt(i);
//     }

//     // Create a blob from the binary data
//     const blob = new Blob([bytes], { type: mimeType });

//     // Generate a filename based on the MIME type
//     const extension = mimeType.split("/")[1];
//     const fileName = `file-${generateUUIDv4()}.${extension}`;

//     // Create a File instance from the blob
//     resolve(new File([blob], fileName, { type: mimeType }));
//   });
// }

function LoadingSpinner() {
  return (
    <svg
      className="w-5 h-5 mr-3 animate-spin text-black"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}

export default AttractionImages;
